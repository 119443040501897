<script>
export default {
  name: "DateTimePickerDialog",
  data() {
    return {
      isVisible: false,
      date: "",
      time: "",
    }
  },
  props: {
    visible: Boolean,
    currentDateTime: String,
  },
  watch: {
    visible: function (newVal) {
      this.isVisible = newVal;
    },
    currentDateTime: function (newVal) {
      this.date = newVal.split("T")[0];
      this.time = newVal.split("T")[1].split(".")[0];
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    confirm() {
      this.$emit('confirm', this.date + "T" + this.time + ":00.000Z");
    },
  },
  mounted() {
    this.isVisible = this.visible;
    this.date = this.currentDateTime.split("T")[0];
    this.time = this.currentDateTime.split("T")[1].split(".")[0];
  }
}
</script>

<template>
  <v-dialog v-model="isVisible" persistent max-width="700">
    <v-card>
      <v-card-title>Pick a date</v-card-title>
      <v-card-text>
        <div class="d-flex justify-space-between">
          <v-date-picker v-model="date" min="2025-01-01" max="2025-01-05"></v-date-picker>
          <v-time-picker v-model="time" format="24hr"></v-time-picker>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="cancel" class="me-2">Cancel</v-btn>
        <v-btn @click="confirm" color="primary" :disabled="date.length<1||time.length<1">Confirm</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>