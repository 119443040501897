<script>
import PlayLiveStreamOrVideo from "@/views/livestream/PlayLiveStreamOrVideo.vue";
import {useLiveStreamStore} from "@/stores/livestream";
import LiveStreamOverview from "@/views/livestream/LiveStreamOverview.vue";

export default {
  name: "LiveStreamIndex",
  data() {
    return {
      showHEVCNotSupportedInfo: false,
      isHEVCSupported: false,
    }
  },
  methods: {
    useLiveStreamStore,
  },
  components: {LiveStreamOverview, PlayLiveStreamOrVideo},
  mounted() {
    const video = document.createElement('video');
    this.isHEVCSupported = video.canPlayType('video/mp4; codecs="hev1.1.6.L93.B0"') || video.canPlayType('video/mp4; codecs="hvc1.1.6.L93.B0"');
    this.showHEVCNotSupportedInfo = !this.isHEVCSupported;

    useLiveStreamStore().fetchActiveLivestreams();
    useLiveStreamStore().fetchRecordings();
  },
};
</script>

<template>
  <div>
    <v-dialog v-model="showHEVCNotSupportedInfo" width="500">
      <v-card>
        <v-card-title>Codec not supported</v-card-title>
        <v-card-text>Your browser does not support the livestream codec. Make sure to use a Chromium based browser or
          Safari on one of the following platforms:
          <ul>
            <li>Windows</li>
            <li>MacOS</li>
            <li>IOS/iPadOS</li>
            <li>Android</li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="showHEVCNotSupportedInfo = false">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card style="height: fit-content" class="rounded-lg">
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col>
              <div class="text-h3">Livestream</div>
            </v-col>
          </v-row>
            <LiveStreamOverview v-if="useLiveStreamStore().streamOrRecordingIndex<0"/>
            <PlayLiveStreamOrVideo v-else/>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<style scoped>

</style>