<script>
import {useLiveStreamStore} from "@/stores/livestream";
import Map from 'ol/Map';
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import {Feature} from "ol";
import {Point} from "ol/geom";
import {transform} from "ol/proj";
import {Icon, Style} from "ol/style";
import {mapState} from "pinia";
import mpegts from 'mpegts.js';
import RecentLiveStreamsList from "@/views/livestream/RecentLiveStreamsList.vue";

export default {
  name: "PlayLiveStreamOrVideo",
  components: {RecentLiveStreamsList},
  data() {
    return {
      liveStreamMap: null,
      view: new View({
        center: transform(useLiveStreamStore().getActiveElement.location, 'EPSG:4326', 'EPSG:3857'),
        zoom: 14
      }),
      clientPointLayer: new VectorLayer({
        className: 'clientPoint',
        source: new VectorSource({
          features: [new Feature(new Point(transform(useLiveStreamStore().getActiveElement.location, 'EPSG:4326', 'EPSG:3857')))],
        }),
        style: new Style({
          image: new Icon({
            color: '#f44336',
            crossOrigin: 'anonymous',
            src: 'marker.svg',
            height: 30,
            anchor: [0.5, 1]
          })
        }),
        visible: true,
        zIndex: 100000
      })
    };
  },
  computed: {
    ...mapState(useLiveStreamStore, ['streamOrRecordingIndex'])
  },
  watch: {
    streamOrRecordingIndex() {
      this.updateMap();
    }
  },
  methods: {
    useLiveStreamStore,
    getFormattedDuration(duration) {
      return (Math.round(duration / 60 * 100) / 100) + ' min';
    },
    initLiveStreamMap() {
      this.liveStreamMap = new Map({
        target: 'liveStreamMap',
        layers: [
          new TileLayer({
            source: new OSM(),
          }),
          this.clientPointLayer
        ],
        view: this.view
      });
    },
    updateMap() {
      this.liveStreamMap.getLayers().forEach(layer => {
        if (layer.getClassName() === 'clientPoint') {
          layer.getSource().getFeatures()[0].getGeometry().setCoordinates(transform(useLiveStreamStore().getActiveElement.location, 'EPSG:4326', 'EPSG:3857'));
        }
      });
      this.liveStreamMap.getView().setCenter(transform(useLiveStreamStore().getActiveElement.location, 'EPSG:4326', 'EPSG:3857'));
    },
    resetLivestream() {
      useLiveStreamStore().isActiveLivestream = false;
      useLiveStreamStore().streamOrRecordingIndex = -1;
    },
    getFormattedDate(isoString) {
      const date = new Date(isoString);
      const options = {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      };
      return date.toLocaleDateString('en-GB', options);
    },
    getFormattedDateTime(isoString) {
      const date = new Date(isoString);
      return this.getFormattedDate(isoString) + ', ' + date.toLocaleTimeString('de-DE', {timeZone: 'UTC'});
    },
  },
  mounted() {
    this.initLiveStreamMap();

    if (useLiveStreamStore().isActiveLivestream) {
      // eslint-disable-next-line no-undef
      if (mpegts.getFeatureList().mseLivePlayback) {
        const videoElement = document.getElementById('liveStreamPlayer');
        // eslint-disable-next-line no-undef
        const player = mpegts.createPlayer({
          type: 'flv',  // could also be mpegts, m2ts, flv
          isLive: true,
          url: useLiveStreamStore().getActiveElement.url
        });
        player.attachMediaElement(videoElement);
        player.load();
      }
    }
  }
};
</script>

<template>
  <v-row>
    <v-col xs="12" sm="12" md="12" lg="7">
      <div style="width: 100%;">
        <video v-if="useLiveStreamStore().isActiveLivestream" id="liveStreamPlayer" style="width: inherit"
               class="rounded-lg"
               controls autoplay></video>
        <video :key="useLiveStreamStore().streamOrRecordingIndex" v-else style="width: inherit" class="rounded-lg"
               autoplay controls
               preload="auto">
          <source :src="useLiveStreamStore().getActiveElement.url" type="video/mp4">
        </video>
        <v-row class="mt-2">
          <v-col xs="12" sm="12" md="6" lg="6">
            <div class="text-h4">{{ useLiveStreamStore().getActiveElement.name }} <span
                v-if="useLiveStreamStore().isActiveLivestream" style="background: red; color: white"
                class="pa-1 rounded-lg">Live</span></div>
            <div class="text-h6" :class="useLiveStreamStore().isActiveLivestream ? 'mt-3': ''"><span
                v-if="useLiveStreamStore().isActiveLivestream">Started at </span>
              {{ getFormattedDateTime(useLiveStreamStore().getActiveElement.date) }}
            </div>
          </v-col>
          <v-col xs="12" sm="12" md="6" lg="6">
            <div id="liveStreamMap" class="rounded-lg"></div>
          </v-col>
        </v-row>
      </div>
    </v-col>
    <v-col xs="12" sm="12" md="12" lg="5">
      <v-btn style="width: 100%"
             @click="resetLivestream">Back to Livestream overview
      </v-btn>
      <RecentLiveStreamsList/>
    </v-col>
  </v-row>
</template>

<style scoped>
.detailsKey {
  width: 100px;
}

#liveStreamMap {
  width: auto;
  height: 22vh;
  overflow: hidden;
}
</style>