<script>
import {useLiveStreamStore} from "@/stores/livestream";
import helpers from "../../helpers/helpers";

export default {
  name: "RecentLiveStreamsList",
  computed: {
    helpers() {
      return helpers;
    }
  },
  methods: {
    useLiveStreamStore,
    getFormattedDate(isoString) {
      const date = new Date(isoString);
      const options = {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      };
      return date.toLocaleDateString('en-GB', options);
    },
    getFormattedDateTime(isoString) {
      const date = new Date(isoString);
      return this.getFormattedDate(isoString) + ', ' + date.toLocaleTimeString('de-DE', {timeZone: 'UTC'});
    },
    getFormattedDuration(duration) {
      return (Math.round(duration / 60 * 100) / 100) + ' min';
    },
    playVideo(index) {
      useLiveStreamStore().setActiveLivestreamOrRecording(index, false);
    },
  },
  data() {
    return {};
  }
};
</script>

<template>
  <div>
    <h2 class="text-h4 my-2">Livestream Recordings</h2>
    <div id="scroll-container">
      <v-row v-for="(recording, index) in useLiveStreamStore().livestreamRecordings" :key="index" class="video-item"
             @click="playVideo(index)">
        <v-col xy="12" sm="6">
          <div class="d-flex rounded-lg video-card">
            <video style="user-select: none; pointer-events: none;">
              <source :src="recording.url" type="video/mp4">
            </video>
          </div>
        </v-col>
        <v-col xy="12" sm="6">
          <div class="text-h6" :style="useLiveStreamStore().isActiveVideo(index) ? 'color: #1976d2' : ''">
            {{ recording.name }}
          </div>
          <div :style="useLiveStreamStore().isActiveVideo(index) ? 'color: #1976d2' : ''">{{
              getFormattedDateTime(recording.date)
            }}
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<style scoped>
@media only screen and (min-width: 1270px) {
  #scroll-container {
    height: 650px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.video-card {
  width: 100%;
  aspect-ratio: 16/9;
  overflow: hidden;
  position: relative;
}

.video-badge {
  color: white;
  background-color: #373737;
  position: absolute;
}

.video-item:hover {
  cursor: pointer;
}
</style>