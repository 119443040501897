<script>
import {useLiveStreamStore} from "@/stores/livestream";
import RequestLivestreamDialog from "@/views/livestream/RequestLivestreamDialog.vue";

export default {
  name: "LiveStreamOverview",
  components: {RequestLivestreamDialog},
  data() {
    return {
      requestLivestreamDialogVisible: false,
    }
  },
  methods: {
    useLiveStreamStore,
    playLiveStream(index) {
      useLiveStreamStore().setActiveLivestreamOrRecording(index, true);
    },
    playVideo(index) {
      useLiveStreamStore().setActiveLivestreamOrRecording(index, false);
    },
    getFormattedDate(isoString) {
      const date = new Date(isoString);
      const options = {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      };
      return date.toLocaleDateString('en-GB', options);
    },
    getFormattedDateTime(isoString) {
      const date = new Date(isoString);
      return this.getFormattedDate(isoString) + ', ' + date.toLocaleTimeString('de-DE', {timeZone: 'UTC'});
    },
    getFormattedDuration(duration) {
      return (Math.round(duration / 60 * 100) / 100) + ' min';
    },
  }
}
</script>

<template>
  <v-row>
    <RequestLivestreamDialog :visible="requestLivestreamDialogVisible" @close="requestLivestreamDialogVisible = false"/>
    <v-col>
      <div class="heading-container justify-space-between">
        <h2 class="text-h4 mb-5">Active Livestreams</h2>
        <v-btn @click="requestLivestreamDialogVisible = true" color="primary" class="rounded-lg">Request new
          Livestream
        </v-btn>
      </div>
      <div v-if="useLiveStreamStore().isFetchingActiveLivestreams" class="text-center mt-15 mb-15">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
        <div class="mt-3">Loading active livestream data from database...</div>
      </div>
      <v-row v-else>
        <v-col v-if="useLiveStreamStore().activeLivestreams.length<1">
          <div class="text-h6">No active Livestreams found.</div>
        </v-col>
        <v-col v-for="(livestream, index) in useLiveStreamStore().activeLivestreams" :key="index" xs="12" sm="12" md="6"
               lg="3">
          <div class="video-item" @click="playLiveStream(index)">
            <div class="d-flex rounded-lg video-card">
              <img class="rounded-lg livestream-img-preview" :src="require('@/assets/livestream_preview.png')"
                   alt="livestream preview"/>
              <span class="ml-1 mt-1 mb-auto pa-1 rounded-lg livestream-badge">Live</span>
            </div>
            <div class="text-h6">{{ livestream.name }}</div>
            <div>
              Started at: {{ getFormattedDateTime(livestream.date) }}
            </div>
          </div>
        </v-col>
      </v-row>
      <h2 class="mt-10 mb-4 text-h4">Livestream Recordings</h2>
      <div v-if="useLiveStreamStore().isFetchingRecordings" class="text-center mt-15 mb-15">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
        <div class="mt-3">Loading recordings from database...</div>
      </div>
      <v-row v-else>
        <v-col v-for="(recording, index) in useLiveStreamStore().livestreamRecordings" :key="index" xs="12" sm="12"
               md="6" lg="3">
          <div class="video-item" @click="playVideo(index)">
            <div class="d-flex rounded-lg video-card">
              <video style="user-select: none; pointer-events: none;">
                <source :src="recording.url" type="video/mp4">
              </video>
            </div>
            <div class="text-h6">{{ recording.name }}</div>
            <div>{{ getFormattedDateTime(recording.date) }}</div>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<style scoped>
.video-card {
  width: 100%;
  aspect-ratio: 16/9;
  overflow: hidden;
  position: relative;
}

.livestream-badge {
  color: white;
  background-color: #ff0000;
  position: absolute;
}

.video-badge {
  color: white;
  background-color: #373737;
  position: absolute;
}

.video-item:hover {
  cursor: pointer;
}

.livestream-img-preview {
  filter: blur(3px);
  margin: -1px;
  padding: 1px;
}

@media only screen and (min-width: 600px) {
  .heading-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 0;
  }
}

.heading-container {
  margin-bottom: 20px;
}
</style>