<script>
import {useLiveStreamStore} from "@/stores/livestream";

export default {
  name: "RequestLivestreamDialog",
  data() {
    return {
      isVisible: false,
      isStreamingRequestLoading: false,
      streamIdentifier: "",
    }
  },
  props: {
    visible: Boolean,
  },
  watch: {
    visible: {
      deep: true,
      async handler(newValue) {
        if (newValue) {
          this.isVisible = true;
          this.isStreamingRequestLoading = true;
          this.streamIdentifier = await useLiveStreamStore().requestLivestream();
          this.isStreamingRequestLoading = false
        }

      }
    },
    isVisible: {
      handler(newValue) {
        if (!newValue) {
          useLiveStreamStore().fetchActiveLivestreams();
          this.$emit('close');
        }
      }
    }
  }
}
</script>

<template>
  <v-dialog v-model="isVisible" width="600">
    <v-card>
      <v-card-title>
        <div class="text-h6">Request new Livestream</div>
      </v-card-title>
      <v-card-text v-if="!isStreamingRequestLoading">
        Enter this unique streaming identifier into the conneXstream streaming launcher application to start a new
        livestream.
        <div class="text-center mt-2" style="font-size: 20px; font-weight: bold">{{ streamIdentifier }}</div>
      </v-card-text>
      <v-card-text v-else>
        <div class="text-center">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
          <div class="mt-3">Requesting stream identifier...</div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="isVisible = false">Finish</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>