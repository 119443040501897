import {defineStore} from "pinia";
import axios_services from "@/axios-services";

export const useMapStore = defineStore('mapStore', {
    state: () => {
        return {
            sistemaAccessToken: "",
        };
    },
    actions: {
        async fetchSistemaAccessToken() {
            await axios_services.post("/auth/oauth2/token_wms").then((response) => {
                this.sistemaAccessToken = response.data['access_token'];
            })
        }
    }
})