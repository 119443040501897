<template>
  <div class="p-0 m-0">
    <div id="map" class="map">
    </div>
    <!--Options Box-->
    <div style="position: absolute; top: 0; left: 0;">
      <v-card
          v-show="!isCardMinimized"
          class="mx-0 rounded-t-0"
          style="width: 100%; border-top-right-radius: 0; border-top-left-radius: 0; border-bottom-left-radius: 0;">
        <v-card-text>
          <!-- Minimize Button (visible only on mobile) -->
          <div class="d-flex justify-end d-md-none">
            <v-btn icon @click="isCardMinimized = true">
              <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
          </div>
          <!-- Responsive container for the content -->
          <div class="d-flex flex-column flex-md-row align-center" style="gap: 16px;">
            <v-select :items="baseLayers" v-model="selectedBaseLayer" label="Base Map"
                      item-value="name"
                      item-text="heading"
                      class="mt-3 mt-md-0 ml-md-0"
                      style="width: 100%; max-width: 280px;"/>
            <v-select :items="layers" class="mt-3 mt-md-0 ml-md-3" v-model="selectedLayer" label="Product"
                      item-value="name"
                      item-text="heading"
                      style="width: 100%; max-width: 280px;"/>
            <template v-if="isPanSharpeningAvailableForLayer">
              <div :class="{'d-flex': true, 'flex-column': isMobile, 'align-center': !isMobile}" style="gap: 16px;">
                <!-- Checkbox for pansharpening -->
                <v-checkbox v-model="isPanSharpeningActive" label="Use pansharpening"></v-checkbox>
                <!-- Slider for pansharpening - shown only if the checkbox is active -->
                <div
                    v-if="isPanSharpeningActive"
                    :class="{'d-flex': true, 'align-center': true, 'mt-2': isMobile}"
                    style="gap: 8px;">
                  <v-slider
                      min="0"
                      max="255"
                      v-model="panSharpeningSliderValue"
                      style="width: 200px"
                      class="mt-4"
                  ></v-slider>
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                          v-bind="attrs"
                          v-on="on"
                          color="primary"
                          style="cursor: pointer;">
                        mdi-information
                      </v-icon>
                    </template>
                    <span>The slider adjusts the degree of pansharpening.</span>
                  </v-tooltip>
                </div>
              </div>
            </template>
          </div>
          <div
              v-if="selectedLayer === 'effis-fwi' || selectedLayer === 's5p-co-total-column' || selectedLayer === 's5p-aerosol-index' || selectedLayer === 'cams-co' || selectedLayer === 'cams-pm'"
              class="mt-5 d-flex flex-column flex-md-row"
              style="gap: 16px;">
            <template v-if="selectedLayer === 'effis-fwi'">
              <div>
                <span>Pick a prediction date</span>
                <v-slider
                    v-model="fwiDateIndex"
                    :tick-labels="fwiPredictionDates.map((date) => date.slice(0, 10))"
                    class="tick-label-rotated pb-12 pr-5"
                    min="0"
                    max="8"
                    step="1"
                    ticks="always"
                    tick-size="4"
                    :style="isMobile? 'width: 260px;' : 'width: 400px;'"
                ></v-slider>
              </div>
            </template>
            <template v-else-if="selectedLayer === 'cams-co' || selectedLayer === 'cams-pm'">
              <div>
                <span>Pick a date</span>
                <v-slider
                    v-model="camsDateIndex"
                    :tick-labels="camsDates.map((date) => date.slice(0, 10))"
                    class="tick-label-rotated pb-12 pr-5"
                    min="0"
                    max="4"
                    step="1"
                    ticks="always"
                    tick-size="4"
                    :style="isMobile? 'width: 260px;' : 'width: 400px;'"
                ></v-slider>
              </div>
            </template>
            <template v-else-if="selectedLayer === 's5p-co-total-column' || selectedLayer === 's5p-aerosol-index'">
              <div class="d-flex flex-column">
                <span class="text-h6">Start Date</span>
                <span>{{ this.s5pStartDateTime }}</span>
                <v-btn @click="s5pPickStartDateTimeDialogOpen = true">Pick Start Date</v-btn>
              </div>
              <div class="d-flex flex-column">
                <span class="text-h6">End Date</span>
                <span>{{ this.s5pEndDateTime }}</span>
                <v-btn @click="s5pPickEndDateTimeDialogOpen = true">Pick End Date</v-btn>
              </div>
            </template>
          </div>

        </v-card-text>
      </v-card>
      <!-- Expand Button (only visible on mobile) -->
      <v-btn
          v-if="isCardMinimized && isMobile"
          class="mx-0"
          block
          @click="isCardMinimized = false">
        <v-icon left>mdi-chevron-down</v-icon>
        Show Options
      </v-btn>
    </div>
    <!--Map Legend-->
    <div
        v-if="(selectedLayer === 'clc-plus' || selectedLayer === 'effis-fwi' || selectedLayer === 'valencia-flood-extent' || selectedLayer === 'valencia-pop' || selectedLayer === 'valencia-flood-affected-pop' || selectedLayer === 's5p-co-total-column' || selectedLayer === 's5p-aerosol-index' || selectedLayer === 'cams-co' || selectedLayer === 'cams-pm') && legendActive"
        style="position: absolute; bottom: 40px; right: 20px; z-index: 100;">
      <v-card
          outlined
          :width="selectedLayer === 'valencia-pop' || selectedLayer === 'valencia-flood-affected-pop' || selectedLayer === 's5p-co-total-column' || selectedLayer === 's5p-aerosol-index' || selectedLayer === 'cams-co' || selectedLayer === 'cams-pm' ? '420' : '340'"
          class="rounded-lg"
      >
        <v-card-title class="pb-0">{{ legendHeading }}
          <v-spacer></v-spacer>
          <v-btn @click="legendActive = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div
              v-if="selectedLayer === 's5p-co-total-column' || selectedLayer === 's5p-aerosol-index' || selectedLayer === 'cams-co' || selectedLayer === 'cams-pm'"
              class="d-flex flex-column">
            <span>{{ sistemaLegendData.title }}</span>
            <div class="d-flex flex-row justify-space-between">
              <span>{{ sistemaLegendData.minLabel }}</span>
              <span>{{ sistemaLegendData.maxLabel }}</span>
            </div>
            <img :src="sistemaLegendData.img" alt="Legend" style="height:20px" class="rounded"/>
          </div>
          <div v-if="selectedLayer === 'valencia-flood-affected-pop'" class="text-body-1"
               :class="tilePopulation>=0 &&(selectedLayer === 'valencia-flood-affected-pop' || selectedLayer === 'valencia-pop') ? 'mb-1' : 'mb-3'">
            Total affected
            population: 195 280
          </div>
          <div
              v-if="tilePopulation>=0 &&(selectedLayer === 'valencia-flood-affected-pop' || selectedLayer === 'valencia-pop')"
              class="text-body-1 mb-3">
            Population per hectare in the selected area: <span class="font-weight-bold">{{ tilePopulation }}</span>
          </div>
          <div v-for="legendItem in legendData" :key="legendItem.hex"
               class="d-flex flex-row align-center mb-1">
            <div class="rounded" style="width:15px; height:15px" :style="'background-color:'+legendItem.hex"></div>
            <div class="ml-2">{{ legendItem.label }}</div>
          </div>
          <div v-if="selectedLayer === 'valencia-pop' || selectedLayer === 'valencia-flood-affected-pop'"
               class="d-flex flex-row align-center mb-1">
            <img :src="require('@/assets/valencia-pop-legend.png')" alt="Valencia Population Distribution Legend"/>
            <div class="ms-3">Population per hectare in area</div>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <!--Map Legend Button-->
    <div
        v-if="selectedLayer === 'clc-plus' || selectedLayer === 'effis-fwi' || selectedLayer === 'valencia-flood-extent' || selectedLayer === 'valencia-pop' || selectedLayer === 'valencia-flood-affected-pop' || selectedLayer === 's5p-co-total-column' || selectedLayer === 's5p-aerosol-index' || selectedLayer === 'cams-co' || selectedLayer === 'cams-pm'"
        style="position: absolute; bottom: 40px; right: 20px; z-index: 90;">
      <v-btn v-if="!legendActive" @click="legendActive = true"
             color="primary" dark rounded fab small>
        <v-icon>mdi-format-list-bulleted</v-icon>
      </v-btn>
    </div>
    <DateTimePickerDialog :visible="s5pPickStartDateTimeDialogOpen" :current-date-time="s5pStartDateTime"
                          @cancel="s5pPickStartDateTimeDialogOpen = false" @confirm="setS5pStartDateTime"/>
    <DateTimePickerDialog :visible="s5pPickEndDateTimeDialogOpen" :current-date-time="s5pEndDateTime"
                          @cancel="s5pPickEndDateTimeDialogOpen = false" @confirm="setS5pEndDateTime"/>
  </div>
</template>
<script>
import View from 'ol/View';
import Map from 'ol/Map';
import "ol/ol.css";
import {useGlobalStore} from "@/stores/global";
import {mapState} from "pinia";
import {BingMaps, TileWMS, XYZ} from "ol/source";
import TileLayer from "ol/layer/Tile";
import RasterSource from "ol/source/Raster";
import ImageLayer from "ol/layer/Image";
import OSM from "ol/source/OSM";
import DateTimePickerDialog from "@/views/map/DateTimePickerDialog.vue";
import {useMapStore} from "@/stores/map";

export default {
  name: "MapService",
  components: {DateTimePickerDialog},
  data() {
    return {
      map: null,
      view: null,
      isCardMinimized: false,
      panCLCPlusRasterSourceBing: null,
      panCLCPlusRasterSourceOSM: null,
      panEffisFwiRasterSourceBing: null,
      panEffisFwiRasterSourceOSM: null,
      panValenciaFloodExtentRasterSourceBing: null,
      panValenciaFloodExtentRasterSourceOSM: null,
      panValenciaPopRasterSourceBing: null,
      panValenciaPopRasterSourceOSM: null,
      panSistemaS5pTotalColumnRasterSourceBing: null,
      panSistemaS5pTotalColumnRasterSourceOSM: null,
      panSistemaS5pAerosolIndexRasterSourceBing: null,
      panSistemaS5pAerosolIndexRasterSourceOSM: null,
      panSistemaCamsCoRasterSourceBing: null,
      panSistemaCamsCoRasterSourceOSM: null,
      panSistemaCamsPmRasterSourceBing: null,
      panSistemaCamsPmRasterSourceOSM: null,
      isPanSharpeningActive: false,
      isPanSharpeningAvailableForLayer: false,
      valenciaPopulationLayer: null,
      tilePopulation: -1,
      fwiDateIndex: 0,
      camsDateIndex: 0,
      camsDates: [
        "2025-01-01T00:00:00.000Z",
        "2025-01-02T00:00:00.000Z",
        "2025-01-03T00:00:00.000Z",
        "2025-01-04T00:00:00.000Z",
        "2025-01-01T00:00:00.000Z"
      ],
      s5pStartDateTime: '2025-01-05T23:00:00.000Z',
      s5pEndDateTime: '2025-01-05T23:59:00.000Z',
      s5pPickStartDateTimeDialogOpen: false,
      s5pPickEndDateTimeDialogOpen: false,
      panSharpeningSliderValue: 255,
      selectedLayer: '',
      selectedBaseLayer: 'osm',
      layers: [{
        name: 'effis-fwi',
        heading: 'EFFIS Forest Weather Index',
        panSharpeningAvailable: true,
      }, {
        name: 'clc-plus',
        heading: 'Copernicus Land Monitoring Service - CLC+ Backbone Raster',
        panSharpeningAvailable: true,
      }, {
        name: 'valencia-flood-extent',
        heading: 'Valencia Flood Extent',
        panSharpeningAvailable: true,
      }, {
        name: 'valencia-pop',
        heading: 'Valencia Population Distribution',
        panSharpeningAvailable: true,
      }, {
        name: 'valencia-flood-affected-pop',
        heading: 'Valencia Flood Affected Population',
        panSharpeningAvailable: true,
      }, {
        name: 's5p-co-total-column',
        heading: 'SISTEMA - Sentinel-5P CO Total Column',
        panSharpeningAvailable: true,
      }, {
        name: 's5p-aerosol-index',
        heading: 'SISTEMA - Sentinel-5P Aerosol Index',
        panSharpeningAvailable: true,
      }, {
        name: 'cams-co',
        heading: 'SISTEMA - European air quality forecast: carbon monixide',
        panSharpeningAvailable: true,
      }, {
        name: 'cams-pm',
        heading: 'SISTEMA - European air quality forecast: particulate matter 10um',
        panSharpeningAvailable: true,
      }
      ],
      baseLayers: [{
        name: 'osm',
        heading: 'OpenStreetMap',
      }, {
        name: 'bing',
        heading: 'Bing Maps',
      }],
      legendActive: true,
    };
  },
  async mounted() {
    await useMapStore().fetchSistemaAccessToken();
    this.initMap();
  },
  computed: {
    ...mapState(useGlobalStore, ['navDrawerExpanded']),
    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    legendHeading() {
      if (this.selectedLayer === 'clc-plus') {
        return "Land Cover";
      } else if (this.selectedLayer === 'effis-fwi') {
        return "Fire Danger";
      } else if (this.selectedLayer === 'valencia-flood-extent') {
        return "Valencia Flood Extent";
      } else if (this.selectedLayer === 'valencia-pop') {
        return "Valencia Population Distribution";
      } else if (this.selectedLayer === 'valencia-flood-affected-pop') {
        return "Valencia Flood Affected Population";
      } else if (this.selectedLayer === 's5p-co-total-column') {
        return "Sentinel-5P CO Total Column";
      } else if (this.selectedLayer === 's5p-aerosol-index') {
        return "Sentinel-5P Aerosol Index";
      } else if (this.selectedLayer === 'cams-co') {
        return "CAMS - Carbon Monoxide";
      } else if (this.selectedLayer === 'cams-pm') {
        return "CAMS - Particulate Matter 10um";
      } else {
        return "No data available";
      }
    },
    legendData() {
      if (this.selectedLayer === 'clc-plus') {
        return [
          {"hex": "#FF0000", "label": "Sealed"},
          {"hex": "#228B22", "label": "Woody - needle leaved trees"},
          {"hex": "#80FF00", "label": "Woody - Broadleaved deciduous trees"},
          {"hex": "#00FF08", "label": "Woody - Broadleaved evergreen trees"},
          {"hex": "#804000", "label": "Low-growing woody plants (bushes, shrubs)"},
          {"hex": "#CCF24D", "label": "Permanent herbaceous"},
          {"hex": "#FFFF80", "label": "Periodically herbaceous"},
          {"hex": "#FF80FF", "label": "Lichens and mosses"},
          {"hex": "#BFBFBF", "label": "Non-and-sparsely-vegetated"},
          {"hex": "#0080FF", "label": "Water"},
          {"hex": "#00FFFF", "label": "Snow and ice"}
        ];
      } else if (this.selectedLayer === 'effis-fwi') {
        return [
          {"hex": "#9cffbe", "label": "Low (< 11.2)"},
          {"hex": "#cddf5e", "label": "Moderate (11.2 - 21.3)"},
          {"hex": "#e6ac00", "label": "High (21.3 - 38.0)"},
          {"hex": "#d8700f", "label": "Very High (38.0 - 50.0)"},
          {"hex": "#ad060e", "label": "Extreme (50.0 - 70.0)"},
          {"hex": "#3a0016", "label": "Very Extreme (> 70.0)"},
        ];
      } else if (this.selectedLayer === 'valencia-flood-extent' || this.selectedLayer === 'valencia-flood-affected-pop') {
        return [
          {"hex": "#abc0ff", "label": "Flooded area"},
        ]
      } else {
        return [];
      }
    },
    sistemaLegendData() {
      if (this.selectedLayer === 's5p-co-total-column') {
        return {
          img: require('@/assets/legends/legend_s5p_carbon_monoxide_total.png'),
          title: "Surface Density",
          minLabel: "0 mol/m^2",
          maxLabel: "0.1 mol/m^2",
        }
      } else if (this.selectedLayer === 's5p-aerosol-index') {
        return {
          img: require('@/assets/legends/legend_s5p_aerosol_index.png'),
          title: "",
          minLabel: "0.5",
          maxLabel: "3",
        }
      } else if (this.selectedLayer === 'cams-co') {
        return {
          img: require('@/assets/legends/legend_cams_co.png'),
          title: "Concentration",
          minLabel: "50 µg/m^3",
          maxLabel: "185 µg/m^3",
        }
      } else if (this.selectedLayer === 'cams-pm') {
        return {
          img: require('@/assets/legends/legend_cams_pm.png'),
          title: "Concentration",
          minLabel: "0.1 µg/m^3",
          maxLabel: "120 µg/m^3",
        }
      }
      return {};
    },
    fwiPredictionDates() {
      const today = new Date();
      const dates = [];
      for (let i = 0; i < 9; i++) {
        const nextDate = new Date(today);
        nextDate.setDate(today.getDate() + 1 + i);
        dates.push(nextDate.toISOString().slice(0, 10) + "T00:00:00Z");
      }
      return dates;
    }
  },
  watch: {
    navDrawerExpanded() {
      if (!useGlobalStore().navDrawerExpanded && window.innerWidth < 672) {
        this.legendActive = false;
      }
    },
    selectedBaseLayer: function () {
      if (!this.isPanSharpeningActive) {
        this.changeBaseLayer(this.selectedBaseLayer);
      } else {
        this.activatePanSharpeningLayer();
      }
    },
    selectedLayer: function () {
      this.s5pStartDateTime = '2025-01-05T23:00:00.000Z';
      this.s5pEndDateTime = '2025-01-05T23:59:00.000Z';
      this.isPanSharpeningActive = false;
      this.removeAllLayers();
      this.changeBaseLayer(this.selectedBaseLayer);
      this.changeLayer(this.selectedLayer);
      this.tilePopulation = -1;
      if (this.selectedLayer === 'valencia-flood-extent' || this.selectedLayer === 'valencia-pop' || this.selectedLayer === 'valencia-flood-affected-pop') {
        this.map.setView(new View({
          center: [-39174.442005060904, 4770167.484532011],
          zoom: 10
        }))
      } else if (this.selectedLayer === 'cams-co' || this.selectedLayer === 'cams-pm') {
        this.map.setView(new View({
          center: [1936959.13980296, 6771246.943972301],
          zoom: 4
        }))
      } else if (this.selectedLayer === 's5p-co-total-column' || this.selectedLayer === 's5p-aerosol-index') {
        this.map.setView(new View({
          center: [-1389267.2451000542, 4196544.20519107],
          zoom: 3
        }))
      }
    },
    isPanSharpeningActive() {
      if (this.isPanSharpeningActive) {
        this.panSharpeningSliderValue = 255;
        this.activatePanSharpeningLayer();
      } else {
        this.removeAllLayers();
        this.changeBaseLayer(this.selectedBaseLayer);
        this.changeLayer(this.selectedLayer, true);
      }
    },
    panSharpeningSliderValue() {
      if (this.selectedLayer === 'effis-fwi') {
        this.panEffisFwiRasterSourceBing.changed();
        this.panEffisFwiRasterSourceOSM.changed();
      } else if (this.selectedLayer === 'clc-plus') {
        this.panCLCPlusRasterSourceBing.changed();
        this.panCLCPlusRasterSourceOSM.changed();
      } else if (this.selectedLayer === 'valencia-flood-extent' || this.selectedLayer === 'valencia-flood-affected-pop') {
        this.panValenciaFloodExtentRasterSourceBing.changed();
        this.panValenciaFloodExtentRasterSourceOSM.changed();
      } else if (this.selectedLayer === 'valencia-pop') {
        this.panValenciaPopRasterSourceBing.changed();
        this.panValenciaPopRasterSourceOSM.changed();
      } else if (this.selectedLayer === 's5p-co-total-column') {
        this.panSistemaS5pTotalColumnRasterSourceBing.changed();
        this.panSistemaS5pTotalColumnRasterSourceOSM.changed();
      } else if (this.selectedLayer === 's5p-aerosol-index') {
        this.panSistemaS5pAerosolIndexRasterSourceBing.changed();
        this.panSistemaS5pAerosolIndexRasterSourceOSM.changed();
      } else if (this.selectedLayer === 'cams-co') {
        this.panSistemaCamsCoRasterSourceOSM.changed();
        this.panSistemaCamsCoRasterSourceBing.changed();
      } else if (this.selectedLayer === 'cams-pm') {
        this.panSistemaCamsPmRasterSourceOSM.changed();
        this.panSistemaCamsPmRasterSourceBing.changed();
      }
    },
    fwiDateIndex() {
      if (this.selectedLayer === 'effis-fwi') {
        this.map.getLayers().forEach(layer => {
          if (layer.getClassName() === 'effis-fwi-pan' || layer.getClassName() === 'effis-fwi') {
            layer.getSource().updateParams({
              'TIME': this.fwiPredictionDates[this.fwiDateIndex]
            })
          } else if (layer.getClassName() === 'effis-fwi-pan-raster-bing') {
            this.setEffisFWIPanSharpeningRasterSourceBing();
            layer.setSource(this.panEffisFwiRasterSourceBing);
          } else if (layer.getClassName() === 'effis-fwi-pan-raster-osm') {
            this.setEffisFWIPanSharpeningRasterSourceOSM();
            layer.setSource(this.panEffisFwiRasterSourceOSM);
          }
        })
      }
    },
    camsDateIndex() {
      if (this.selectedLayer === 'cams-co') {
        this.map.getLayers().forEach(layer => {
          if (layer.getClassName() === 'cams-co-pan' || layer.getClassName() === 'cams-co') {
            layer.getSource().updateParams({
              'TIME': this.camsDates[this.camsDateIndex]
            })
          } else if (layer.getClassName() === 'cams-co-pan-raster-bing') {
            this.setCamsCoPanSharpeningRasterSourceBing();
            layer.setSource(this.panSistemaCamsCoRasterSourceBing);
          } else if (layer.getClassName() === 'cams-co-pan-raster-osm') {
            this.setCamsCoPanSharpeningRasterSourceOSM();
            layer.setSource(this.panSistemaCamsCoRasterSourceOSM);
          }
        });
      } else if (this.selectedLayer === 'cams-pm') {
        this.map.getLayers().forEach(layer => {
          if (layer.getClassName() === 'cams-pm-pan' || layer.getClassName() === 'cams-pm') {
            layer.getSource().updateParams({
              'TIME': this.camsDates[this.camsDateIndex]
            })
          } else if (layer.getClassName() === 'cams-pm-pan-raster-bing') {
            this.setCamsPmPanSharpeningRasterSourceBing();
            layer.setSource(this.panSistemaCamsPmRasterSourceBing);
          } else if (layer.getClassName() === 'cams-pm-pan-raster-osm') {
            this.setCamsPmPanSharpeningRasterSourceOSM();
            layer.setSource(this.panSistemaCamsPmRasterSourceOSM);
          }
        })
      }
    },
    s5pStartDateTime() {
      this.s5pDateChangeLayerUpdate();
    },
    s5pEndDateTime() {
      this.s5pDateChangeLayerUpdate();
    }
  },
  methods: {
    initMap() {
      this.view = new View({
        center: [1268644, 5985229],
        zoom: 12,
        minZoom: 0,
        maxZoom: 19
      })
      this.map = new Map({
        target: 'map',
        view: this.view,
      });
      this.initBaseLayers();
      this.initLayers();
      this.initPanSharpeningLayers();
    },
    initBaseLayers() {
      this.baseLayers.forEach((layer) => {
        let newLayer;
        if (layer.name === 'osm') {
          newLayer = new TileLayer({
            source: new OSM(),
            className: layer.name,
            visible: true,
            zIndex: 10,
          });
        } else if (layer.name === 'bing') {
          newLayer = new TileLayer({
            source: new BingMaps({
              key: process.env.VUE_APP_BING_KEY,
              imagerySet: 'Aerial',
            }),
            className: layer.name,
            visible: false,
            zIndex: 10,
          });
        }
        this.map.addLayer(newLayer);
      });
    },
    initLayers() {
      this.layers.forEach((layer) => {
        let newLayer;
        if (layer.name === 'effis-fwi') {
          newLayer = new TileLayer({
            source: this.getEffisFWISource(),
            className: layer.name,
            visible: false,
            zIndex: 11,
          });
        } else if (layer.name === 'clc-plus') {
          newLayer = new TileLayer({
            source: this.getClcPlusSource(),
            className: layer.name,
            visible: false,
            zIndex: 11,
          });
        } else if (layer.name === 'valencia-flood-extent') {
          newLayer = new TileLayer({
            source: this.getValenciaFloodExtentSource(),
            className: layer.name,
            visible: false,
            zIndex: 11,
          });
        } else if (layer.name === 'valencia-pop') {
          newLayer = new TileLayer({
            source: this.getValenciaPopSource(),
            className: layer.name,
            visible: false,
            zIndex: 11,
          });
        } else if (layer.name === 'valencia-flood-affected-pop') {
          newLayer = new TileLayer({
            source: this.getValenciaFloodAffectedPopSource(),
            className: layer.name,
            visible: false,
            zIndex: 11,
          });
        } else if (layer.name === 's5p-co-total-column') {
          newLayer = new TileLayer({
            source: this.getSistemaS5pCoTotalColumnSource(),
            wrapX: true,
            className: layer.name,
            visible: false,
            zIndex: 11,
          });
        } else if (layer.name === 's5p-aerosol-index') {
          newLayer = new TileLayer({
            source: this.getSistemaS5pAerosolIndexSource(),
            wrapX: true,
            className: layer.name,
            visible: false,
            zIndex: 11,
          });
        } else if (layer.name === 'cams-co') {
          newLayer = new TileLayer({
            source: this.getSistemaCamsCoSource(),
            wrapX: true,
            className: layer.name,
            visible: false,
            zIndex: 11,
          });
        } else if (layer.name === 'cams-pm') {
          newLayer = new TileLayer({
            source: this.getSistemaCamsPmSource(),
            wrapX: true,
            className: layer.name,
            visible: false,
            zIndex: 11,
          });
        }
        this.map.addLayer(newLayer);
      });

      this.map.on('singleclick', async (evt) => {
        if (this.selectedLayer === 'valencia-pop' || this.selectedLayer === 'valencia-flood-affected-pop') {
          const viewResolution = /** @type {number} */ (this.view.getResolution());
          const url = this.getValenciaPopSource().getFeatureInfoUrl(
              evt.coordinate,
              viewResolution,
              'EPSG:3857',
              {'INFO_FORMAT': 'application/json'},
          );
          await fetch(url)
              .then(res => res.json())
              .then(rawObject => {
                if (rawObject["features"].length > 0) {
                  this.tilePopulation = Math.round(rawObject["features"][0]["properties"]["GRAY_INDEX"]);
                } else {
                  this.tilePopulation = 0;
                }
              }).catch(() => {
                this.tilePopulation = 0;
              })
        }
      });

      this.map.on('pointermove', (evt) => {
        if (this.selectedLayer === 'valencia-pop' || this.selectedLayer === 'valencia-flood-affected-pop') {
          if (evt.dragging) {
            return;
          }

          const data = this.valenciaPopulationLayer.getData(evt.pixel);
          const hit = data && data[3] > 0; // transparent pixels have zero for data[3]
          this.map.getTargetElement().style.cursor = hit ? 'pointer' : '';
        }
      });
    },
    initPanSharpeningLayers() {
      this.initFwiPanSharpeningLayer();
      this.initClcPlusPanSharpeningLayer();
      this.initValenciaFloodExtentPanSharpeningLayer();
      this.initValenciaPopulationPanSharpeningLayer();
      this.initSistemaS5pCoTotalColumnPanSharpeningLayer();
      this.initSistemaS5pAerosolIndexPanSharpeningLayer();
      this.initSistemaCamsCoPanSharpeningLayer();
      this.initSistemaCamsPmPanSharpeningLayer();
    },
    changeBaseLayer(layerName) {
      this.map.getLayers().forEach(layer => {
        if (layer.getZIndex() === 10) layer.setVisible(false);
        if (layer.getClassName() === layerName) layer.setVisible(true);
      });
    },
    changeLayer(layerName) {
      const layerDetails = this.layers.find(layer => layer.name === layerName);
      this.isPanSharpeningAvailableForLayer = layerDetails.panSharpeningAvailable;
      this.map.getLayers().forEach(layer => {
        if (layerName === 'valencia-flood-affected-pop') {
          if (layer.getClassName() === 'valencia-flood-extent') layer.setVisible(true);
        }
        if (layer.getClassName() === layerName) {
          layer.setVisible(true);
          if (layerName === 'valencia-pop' || layerName === 'valencia-flood-affected-pop') {
            this.valenciaPopulationLayer = layer;
          }
        }
      })
    },
    s5pDateChangeLayerUpdate() {
      this.map.getLayers().forEach(layer => {
        if (layer.getClassName() === 's5p-co-total-column' || layer.getClassName() === 's5p-aerosol-index' || layer.getClassName() === 's5p-aerosol-index-pan' || layer.getClassName() === 's5p-co-total-column-pan') {
          const layerToDelete = layer;
          this.map.removeLayer(layerToDelete);
          this.map.addLayer(new TileLayer({
            source: layerToDelete.getClassName() === 's5p-co-total-column' || layerToDelete.getClassName() === 's5p-co-total-column-pan' ? this.getSistemaS5pCoTotalColumnSource() : this.getSistemaS5pAerosolIndexSource(),
            wrapX: true,
            className: layerToDelete.getClassName(),
            visible: this.selectedLayer === layerToDelete.getClassName() || ((this.selectedLayer === layerToDelete.getClassName().replace('-pan', '') && this.isPanSharpeningActive)),
            zIndex: 11,
          }));
        } else if (layer.getClassName() === 's5p-co-total-column-pan-raster-bing') {
          this.setSistemaS5pCoTotalColumnPanSharpeningRasterSourceBing();
          layer.setSource(this.panSistemaS5pTotalColumnRasterSourceBing);
        } else if (layer.getClassName() === 's5p-co-total-column-pan-raster-osm') {
          this.setSistemaS5pCoTotalColumnPanSharpeningRasterSourceOSM();
          layer.setSource(this.panSistemaS5pTotalColumnRasterSourceOSM);
        } else if (layer.getClassName() === 's5p-aerosol-index-pan-raster-bing') {
          this.setSistemaS5pAerosolIndexPanSharpeningRasterSourceBing();
          layer.setSource(this.panSistemaS5pAerosolIndexRasterSourceBing);
        } else if (layer.getClassName() === 's5p-aerosol-index-pan-raster-osm') {
          this.setSistemaS5pAerosolIndexPanSharpeningRasterSourceOSM();
          layer.setSource(this.panSistemaS5pAerosolIndexRasterSourceOSM);
        }
      });
    },
    setS5pStartDateTime(date) {
      this.s5pStartDateTime = date;
      this.s5pPickStartDateTimeDialogOpen = false;
    },
    setS5pEndDateTime(date) {
      this.s5pEndDateTime = date;
      this.s5pPickEndDateTimeDialogOpen = false;
    },
    activatePanSharpeningLayer() {
      this.removeAllLayers();
      this.tilePopulation = -1;
      this.panSharpeningSliderValue = 255;
      if (this.selectedLayer === 'effis-fwi') {
        this.map.getLayers().forEach(layer => {
          if (layer.getClassName() === 'effis-fwi-pan') layer.setVisible(true);
          if (this.selectedBaseLayer === 'bing') {
            if (layer.getClassName() === 'effis-fwi-pan-raster-bing') layer.setVisible(true);
          } else if (this.selectedBaseLayer === 'osm') {
            if (layer.getClassName() === 'effis-fwi-pan-raster-osm') layer.setVisible(true);
          }
        });
      } else if (this.selectedLayer === 'clc-plus') {
        this.map.getLayers().forEach(layer => {
          if (layer.getClassName() === 'clc-plus-pan') layer.setVisible(true);
          if (this.selectedBaseLayer === 'bing') {
            if (layer.getClassName() === 'clc-plus-pan-raster-bing') layer.setVisible(true);
          } else if (this.selectedBaseLayer === 'osm') {
            if (layer.getClassName() === 'clc-plus-pan-raster-osm') layer.setVisible(true);
          }
        })
      } else if (this.selectedLayer === 'valencia-flood-extent') {
        this.map.getLayers().forEach(layer => {
          if (layer.getClassName() === 'valencia-flood-extent-pan') layer.setVisible(true);
          if (this.selectedBaseLayer === 'bing') {
            if (layer.getClassName() === 'valencia-flood-extent-pan-raster-bing') layer.setVisible(true);
          } else if (this.selectedBaseLayer === 'osm') {
            if (layer.getClassName() === 'valencia-flood-extent-pan-raster-osm') layer.setVisible(true);
          }
        });
      } else if (this.selectedLayer === 'valencia-pop') {
        this.map.getLayers().forEach(layer => {
          if (layer.getClassName() === 'valencia-pop-pan') {
            layer.setVisible(true);
            this.valenciaPopulationLayer = layer;
          }
          if (this.selectedBaseLayer === 'bing') {
            if (layer.getClassName() === 'valencia-pop-pan-raster-bing') layer.setVisible(true);
          } else if (this.selectedBaseLayer === 'osm') {
            if (layer.getClassName() === 'valencia-pop-pan-raster-osm') layer.setVisible(true);
          }
        });
      } else if (this.selectedLayer === 'valencia-flood-affected-pop') {
        this.map.getLayers().forEach(layer => {
          if (layer.getClassName() === 'valencia-flood-extent-pan') layer.setVisible(true);
          else if (layer.getClassName() === 'valencia-flood-affected-pop') layer.setVisible(true);
          if (this.selectedBaseLayer === 'bing') {
            if (layer.getClassName() === 'valencia-flood-extent-pan-raster-bing') layer.setVisible(true);
          } else if (this.selectedBaseLayer === 'osm') {
            if (layer.getClassName() === 'valencia-flood-extent-pan-raster-osm') layer.setVisible(true);
          }
        });
      } else if (this.selectedLayer === 's5p-co-total-column') {
        this.map.getLayers().forEach(layer => {
          if (layer.getClassName() === 's5p-co-total-column-pan') layer.setVisible(true);
          if (this.selectedBaseLayer === 'bing') {
            if (layer.getClassName() === 's5p-co-total-column-pan-raster-bing') layer.setVisible(true);
          } else if (this.selectedBaseLayer === 'osm') {
            if (layer.getClassName() === 's5p-co-total-column-pan-raster-osm') layer.setVisible(true);
          }
        })
      } else if (this.selectedLayer === 's5p-aerosol-index') {
        this.map.getLayers().forEach(layer => {
          if (layer.getClassName() === 's5p-aerosol-index-pan') layer.setVisible(true);
          if (this.selectedBaseLayer === 'bing') {
            if (layer.getClassName() === 's5p-aerosol-index-pan-raster-bing') layer.setVisible(true);
          } else if (this.selectedBaseLayer === 'osm') {
            if (layer.getClassName() === 's5p-aerosol-index-pan-raster-osm') layer.setVisible(true);
          }
        })
      } else if (this.selectedLayer === 'cams-co') {
        this.map.getLayers().forEach(layer => {
          if (layer.getClassName() === 'cams-co-pan') layer.setVisible(true);
          if (this.selectedBaseLayer === 'bing') {
            if (layer.getClassName() === 'cams-co-pan-raster-bing') layer.setVisible(true);
          } else if (this.selectedBaseLayer === 'osm') {
            if (layer.getClassName() === 'cams-co-pan-raster-osm') layer.setVisible(true);
          }
        })
      } else if (this.selectedLayer === 'cams-pm') {
        this.map.getLayers().forEach(layer => {
          if (layer.getClassName() === 'cams-pm-pan') layer.setVisible(true);
          if (this.selectedBaseLayer === 'bing') {
            if (layer.getClassName() === 'cams-pm-pan-raster-bing') layer.setVisible(true);
          } else if (this.selectedBaseLayer === 'osm') {
            if (layer.getClassName() === 'cams-pm-pan-raster-osm') layer.setVisible(true);
          }
        })
      }
    },
    initFwiPanSharpeningLayer() {
      this.createPanSharpeningLayer(
          this.getEffisFWISource(),
          "effis-fwi-pan",
          "panEffisFwiRasterSourceBing",
          "panEffisFwiRasterSourceOSM"
      )
    },
    initClcPlusPanSharpeningLayer() {
      this.createPanSharpeningLayer(
          this.getClcPlusSource(),
          "clc-plus-pan",
          "panCLCPlusRasterSourceBing",
          "panCLCPlusRasterSourceOSM"
      )
    },
    initValenciaFloodExtentPanSharpeningLayer() {
      this.createPanSharpeningLayer(
          this.getValenciaFloodExtentSource(),
          "valencia-flood-extent-pan",
          "panValenciaFloodExtentRasterSourceBing",
          "panValenciaFloodExtentRasterSourceOSM"
      );
    },
    initValenciaPopulationPanSharpeningLayer() {
      this.createPanSharpeningLayer(
          this.getValenciaPopSource(),
          "valencia-pop-pan",
          "panValenciaPopRasterSourceBing",
          "panValenciaPopRasterSourceOSM"
      );
    },
    initSistemaS5pCoTotalColumnPanSharpeningLayer() {
      this.createPanSharpeningLayer(
          this.getSistemaS5pCoTotalColumnSource(),
          "s5p-co-total-column-pan",
          "panSistemaS5pTotalColumnRasterSourceBing",
          "panSistemaS5pTotalColumnRasterSourceOSM"
      );
    },
    initSistemaS5pAerosolIndexPanSharpeningLayer() {
      this.createPanSharpeningLayer(
          this.getSistemaS5pAerosolIndexSource(),
          "s5p-aerosol-index-pan",
          "panSistemaS5pAerosolIndexRasterSourceBing",
          "panSistemaS5pAerosolIndexRasterSourceOSM"
      );
    },
    initSistemaCamsCoPanSharpeningLayer() {
      this.createPanSharpeningLayer(
          this.getSistemaCamsCoSource(),
          "cams-co-pan",
          "panSistemaCamsCoRasterSourceBing",
          "panSistemaCamsCoRasterSourceOSM"
      );
    },
    initSistemaCamsPmPanSharpeningLayer() {
      this.createPanSharpeningLayer(
          this.getSistemaCamsPmSource(),
          "cams-pm-pan",
          "panSistemaCamsPmRasterSourceBing",
          "panSistemaCamsPmRasterSourceOSM"
      );
    },
    createPanSharpeningLayer(baseSource, layerClassName, bingSourceVarName, osmSourceVarName) {
      // Create raster sources for Bing and OSM
      this.createPanSharpeningRasterSource(baseSource, "bing", bingSourceVarName);
      this.createPanSharpeningRasterSource(baseSource, "osm", osmSourceVarName);

      // Add a TileLayer for the base source
      this.map.addLayer(new TileLayer({
        source: baseSource,
        className: layerClassName,
        visible: false,
        zIndex: 11,
      }));

      // Add ImageLayers for Bing and OSM raster sources
      this.map.addLayer(
          new ImageLayer({
            source: this[bingSourceVarName],
            className: `${layerClassName}-raster-bing`,
            visible: false,
            zIndex: 12
          })
      );

      this.map.addLayer(
          new ImageLayer({
            source: this[osmSourceVarName],
            className: `${layerClassName}-raster-osm`,
            visible: false,
            zIndex: 12
          })
      );
    },
    createPanSharpeningRasterSource(source, sourceBaseLayerType, targetProperty) {
      let sourceBaseLayer;
      if (sourceBaseLayerType === "bing") {
        sourceBaseLayer = new BingMaps({
          key: process.env.VUE_APP_BING_KEY,
          imagerySet: "Aerial",
        });
      } else {
        sourceBaseLayer = new OSM();
      }
      this[targetProperty] = new RasterSource({
        sources: [sourceBaseLayer, source],
        threads: 4,
        operation: this.getPanSharpeningOperation(),
      });
      this[targetProperty].on("beforeoperations", (event) => {
        event.data.sliderdata = this.panSharpeningSliderValue;
        event.data.sliderdata1 = -1;
      });
    },
    getPanSharpeningOperation() {
      return function (pixels, data) {
        function hslToRgb(h, s, l) {
          let r, g, b;
          if (s === 0) {
            r = g = b = l; // achromatic
          } else {
            // eslint-disable-next-line no-inner-declarations
            function hue2rgb(p, q, t) {
              if (t < 0) t += 1;
              if (t > 1) t -= 1;
              if (t < 1 / 6) return p + (q - p) * 6 * t;
              if (t < 1 / 2) return q;
              if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
              return p;
            }

            const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
            const p = 2 * l - q;
            r = hue2rgb(p, q, h + 1 / 3);
            g = hue2rgb(p, q, h);
            b = hue2rgb(p, q, h - 1 / 3);
          }
          return [r * 255, g * 255, b * 255];
        }

        function rgbToHsl(r, g, b) {
          r /= 255;
          g /= 255;
          b /= 255;
          const max = Math.max(r, g, b), min = Math.min(r, g, b);
          let h, s, l = (max + min) / 2;
          if (max === min) {
            h = s = 0; // achromatic
          } else {
            const d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            switch (max) {
              case r:
                h = (g - b) / d + (g < b ? 6 : 0);
                break;
              case g:
                h = (b - r) / d + 2;
                break;
              case b:
                h = (r - g) / d + 4;
                break;
            }
            h /= 6;
          }
          return [h, s, l];
        }

        const pixelHsl = rgbToHsl(pixels[0][0], pixels[0][1], pixels[0][2]);
        const pixelHsl1 = rgbToHsl(pixels[1][0], pixels[1][1], pixels[1][2]);
        const pixelResult = [...pixelHsl];
        pixelResult[0] = pixelHsl1[0];
        pixelResult[1] = pixelHsl1[1];
        const [r, g, b] = hslToRgb(pixelResult[0], pixelResult[1], pixelResult[2]);
        pixels[0][0] = r;
        pixels[0][1] = g;
        pixels[0][2] = b;
        pixels[0][3] = data.sliderdata;

        if (data.sliderdata1 > -1) {
          if (
              pixels[1][0] > data.sliderdata1 * 255 &&
              pixels[1][0] < data.sliderdata1 * 255 + 255 / 12
          ) {
            pixels[0][0] = 255;
            pixels[0][1] = 255;
            pixels[0][2] = 255;
          }
        }
        return pixels[0];
      };
    },
    setEffisFWIPanSharpeningRasterSourceBing() {
      this.createPanSharpeningRasterSource(this.getEffisFWISource(), "bing", "panEffisFwiRasterSourceBing");
    },
    setEffisFWIPanSharpeningRasterSourceOSM() {
      this.createPanSharpeningRasterSource(this.getEffisFWISource(), "osm", "panEffisFwiRasterSourceOSM");
    },
    setSistemaS5pCoTotalColumnPanSharpeningRasterSourceBing() {
      this.createPanSharpeningRasterSource(this.getSistemaS5pCoTotalColumnSource(), "bing", "panSistemaS5pTotalColumnRasterSourceBing");
    },
    setSistemaS5pCoTotalColumnPanSharpeningRasterSourceOSM() {
      this.createPanSharpeningRasterSource(this.getSistemaS5pCoTotalColumnSource(), "osm", "panSistemaS5pTotalColumnRasterSourceOSM");
    },
    setSistemaS5pAerosolIndexPanSharpeningRasterSourceBing() {
      this.createPanSharpeningRasterSource(this.getSistemaS5pAerosolIndexSource(), "bing", "panSistemaS5pAerosolIndexRasterSourceBing");
    },
    setSistemaS5pAerosolIndexPanSharpeningRasterSourceOSM() {
      this.createPanSharpeningRasterSource(this.getSistemaS5pAerosolIndexSource(), "osm", "panSistemaS5pAerosolIndexRasterSourceOSM");
    },
    setCamsCoPanSharpeningRasterSourceBing() {
      this.createPanSharpeningRasterSource(this.getSistemaCamsCoSource(), "bing", "panSistemaCamsCoRasterSourceBing");
    },
    setCamsCoPanSharpeningRasterSourceOSM() {
      this.createPanSharpeningRasterSource(this.getSistemaCamsCoSource(), "osm", "panSistemaCamsCoRasterSourceOSM");
    },
    setCamsPmPanSharpeningRasterSourceBing() {
      this.createPanSharpeningRasterSource(this.getSistemaCamsPmSource(), "bing", "panSistemaCamsPmRasterSourceBing");
    },
    setCamsPmPanSharpeningRasterSourceOSM() {
      this.createPanSharpeningRasterSource(this.getSistemaCamsPmSource(), "osm", "panSistemaCamsPmRasterSourceOSM");
    },
    getEffisFWISource() {
      return new TileWMS({
        url: "https://mapproxy.connexstream.com/service",
        params: {
          'LAYERS': 'effis_layer',
          'TIME': this.fwiPredictionDates[this.fwiDateIndex] + 'T00:00:00Z',
        },
        attributions: "EFFIS - Copernicus.",
        crossOrigin: 'anonymous'
      });
    },
    getClcPlusSource() {
      return new XYZ({
        url: 'https://tileserver.showroom.geoville.com/CLCplus_RASTER_2018/{z}/{x}/{y}.png',
        maxZoom: 13,
        crossOrigin: '',
        attributions: "CLC+ backbone: European Unions Copernicus Land Monitoring Service information.",
      })
    },
    getValenciaFloodExtentSource() {
      return new TileWMS({
        url: "https://geoserver.geoville.com/geoserver/smart_connect/wms",
        params: {
          'VERSION': '1.1.0',
          'LAYERS': 'smart_connect:valencia_flood_extent',
          'TILED': true,
        },
        attributions: "Valencia Flood Extent - Geoville.",
        crossOrigin: 'anonymous'
      });
    },
    getValenciaPopSource() {
      return new TileWMS({
        url: "https://geoserver.geoville.com/geoserver/smart_connect/wms",
        params: {
          'VERSION': '1.1.0',
          'LAYERS': 'smart_connect:valencia_flood_extent_pop',
          'TILED': true,
        },
        attributions: "Valencia Population Distribution - Geoville.",
        crossOrigin: 'anonymous'
      })
    },
    getValenciaFloodAffectedPopSource() {
      return new TileWMS({
        url: "https://geoserver.geoville.com/geoserver/smart_connect/wms",
        params: {
          'VERSION': '1.1.0',
          'LAYERS': 'smart_connect:valencia_flood_extent_pop_masked',
          'TILED': true,
        },
        attributions: "Valencia Flood Extent Population - Geoville.",
        crossOrigin: 'anonymous'
      })
    },
    getSistemaCustomWMSLoader() {
      return function (tile, src) {
        const client = new XMLHttpRequest();
        client.open('GET', src);
        client.responseType = 'arraybuffer';
        client.setRequestHeader("Authorization", `Bearer ${useMapStore().sistemaAccessToken}`);
        client.onload = function () {
          const arrayBufferView = new Uint8Array(this.response);
          const blob = new Blob([arrayBufferView], {type: 'image/png'});
          const urlCreator = window.URL || (window).webkitURL;
          const imageUrl = urlCreator.createObjectURL(blob);
          tile.getImage().src = imageUrl;
        };
        // Check if wms response status is 401, if so - get new token from api
        client.onreadystatechange = function () {
          if (client.readyState === XMLHttpRequest.DONE && client.status === 401) {
            console.log("Token not valid anymore, refreshing...");
            useMapStore().fetchSistemaAccessToken();
          }
        }
        client.send();
      }
    },
    getSistemaS5pCoTotalColumnSource() {
      return new TileWMS({
        url: "https://wms.eden.destine.eu/wmts",
        params: {
          'TRANSPARENT': 'TRUE',
          'LAYERS': 'EO:MEEO:DAT:SENTINEL-5P:COG|L2__CO_____carbonmonoxide-total-column',
          'CRS': 'EPSG:3857',
          'TIME': `${this.s5pStartDateTime},${this.s5pEndDateTime}`,
          'VERSION': '1.1.1',
        },
        tileLoadFunction: this.getSistemaCustomWMSLoader(),
      })
    },
    getSistemaS5pAerosolIndexSource() {
      return new TileWMS({
        url: "https://wms.eden.destine.eu/wmts",
        params: {
          'TRANSPARENT': 'TRUE',
          'LAYERS': 'EO:MEEO:DAT:SENTINEL-5P:COG|L2__AER_AI_aerosol-index-340-380',
          'TIME': `${this.s5pStartDateTime},${this.s5pEndDateTime}`,
          'CRS': 'EPSG:3857',
          'VERSION': '1.1.1',
        },
        tileLoadFunction: this.getSistemaCustomWMSLoader(),
      })
    },
    getSistemaCamsCoSource() {
      return new TileWMS({
        url: "https://wms.eden.destine.eu/wmts",
        params: {
          'TRANSPARENT': 'TRUE',
          'LAYERS': 'EO:MEEO:DAT:CAMS_EUROPE_AIR_QUALITY_FORECASTS:COG|carbon_monoxide',
          'TIME': this.camsDates[this.camsDateIndex],
          'CRS': 'EPSG:3857',
          'VERSION': '1.1.1',
        },
        tileLoadFunction: this.getSistemaCustomWMSLoader(),
      })
    },
    getSistemaCamsPmSource() {
      return new TileWMS({
        url: "https://wms.eden.destine.eu/wmts",
        params: {
          'TRANSPARENT': 'TRUE',
          'LAYERS': 'EO:MEEO:DAT:CAMS_EUROPE_AIR_QUALITY_FORECASTS:COG|particulate_matter_10um',
          'TIME': this.camsDates[this.camsDateIndex],
          'CRS': 'EPSG:3857',
          'VERSION': '1.1.1',
        },
        tileLoadFunction: this.getSistemaCustomWMSLoader(),
      })
    },
    removeAllLayers() {
      this.map.getLayers().forEach(layer => {
        layer.setVisible(false);
      });
    }
  },
}
;
</script>
<style scoped>
#map {
  width: auto;
  height: calc(100vh - 48px);
  background-color: #242423;
  /* important: keeps the design edge: */
  overflow: hidden;
}

::v-deep .ol-zoom {
  visibility: hidden;
}

::v-deep(.tick-label-rotated .v-slider__tick-label) {
  transform: rotate(50deg) !important;
  transform-origin: left center;
  white-space: nowrap; /* Prevent text from wrapping */
}
</style>