import {defineStore} from "pinia";
import axios_services from "@/axios-services";

export const useLiveStreamStore = defineStore('liveStreamStore', {
    state: () => {
        return {
            activeLivestreams: [],
            livestreamRecordings: [],
            orderId: 4,
            isFetchingActiveLivestreams: false,
            isFetchingRecordings: false,

            streamOrRecordingIndex: -1,
            isActiveLivestream: false,
        };
    },
    getters: {
        isActiveVideo: (state) => {
            return (index) => state.streamOrRecordingIndex === index && !state.isActiveLivestream;
        },
        getActiveElement: (state) => {
            if (state.isActiveLivestream) {
                return state.activeLivestreams[state.streamOrRecordingIndex];
            } else {
                return state.livestreamRecordings[state.streamOrRecordingIndex];
            }
        }
    },
    actions: {
        async requestLivestream() {
            let streamRequestResponse = "";
            const requestBody = {
                order_id: this.orderId,
                active: true,
            }
            await axios_services.post("/drone_management/request_new_drone_stream", requestBody).then((response) => {
                streamRequestResponse = response.data["stream_identifier"];
            }).catch((error) => {
                console.log("error: cannot request new livestream", error);
            });
            return streamRequestResponse;
        },
        async fetchActiveLivestreams() {
            this.activeLivestreams = [];
            this.isFetchingActiveLivestreams = true;
            await axios_services.get("/drone_management/get_active_streams_by_order_id/" + this.orderId).then((response) => {
                response.data["drone_streams"].forEach(stream => {
                    if (stream.active) {
                        this.activeLivestreams.push({
                            id: stream["id"],
                            name: stream["name"],
                            location: [11.435724, 47.262545],
                            date: stream["created_on"],
                            url: stream["stream_url"],
                        });
                    }
                })
            }).catch((error) => {
                console.log("error: cannot fetch data", error)
            });
            this.isFetchingActiveLivestreams = false;
        },
        async fetchRecordings() {
            this.livestreamRecordings = [];
            /*this.isFetchingRecordings = true;
            await axios_services.get("/drone_management/get_streams_data_by_order_id/" + this.orderId).then((response) => {
                response.data["drone_streams"].forEach(stream => {
                    if (!stream.active) {
                        stream["files"].forEach((file, index) => {
                            let nameSuffix = stream["files"].length > 1 ? " - Part " + (index + 1) + "/" + (stream["files"].length) : "";
                            this.livestreamRecordings.push({
                                id: file["filename"],
                                name: stream["name"] + nameSuffix,
                                date: stream["created_on"],
                                url: file["download_url"][0],
                                location: [11.45059, 47.25587],
                            });
                        });
                    }
                })
                console.log(this.livestreamRecordings);
            }).catch((error) => {
                console.log("error: cannot fetch data", error)
            });
            this.isFetchingRecordings = false;*/
            this.livestreamRecordings = [{
                date: "2025-02-17T16:06:31.916916",
                id: "GEO-TES-894",
                name: "GEO-TES-894",
                url: "https://staticfiles.geoville.com/data/connexstream/GEO-TES-894_1739793810103.mp4",
                location: [11.435724, 47.262545],
            }, {
                date: "2025-02-17T15:34:09.916916",
                id: "GEO-TES-965",
                name: "GEO-TES-965",
                url: "https://staticfiles.geoville.com/data/connexstream/GEO-TES-965_1739796661217-00.00.52.856-00.02.46.533.mp4",
                location: [11.435724, 47.262545],
            }, {
                date: "2025-02-17T15:15:35.916916",
                id: "GEO-TES-981",
                name: "GEO-TES-981",
                url: "https://staticfiles.geoville.com/data/connexstream/GEO-TES-981_1739797828975-00.01.21.785-00.03.19.565.mp4",
                location: [11.435724, 47.262545],
            }];
        },
        setActiveLivestreamOrRecording(index, isLivestream) {
            this.isActiveLivestream = isLivestream;
            this.streamOrRecordingIndex = index;
        }
    },
});